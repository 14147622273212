import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { MessageInput, useChatContext } from 'stream-chat-react';
import { useSelector } from 'react-redux';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import { MessageInputPlaceholder } from '../placeholders/MessageInputPlaceholder';
import { t } from 'i18next';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { encodeToMp3 } from './GetStream/encoders/mp3';

export function TeamMessageInput() {
    const { channel } = useChatContext();
    const userCanUseFrozenChannel = useSelector(selectHasAppAccess(AppName.ConnectCreateDelete));
    const isConnectAudioMessagesEnabled = useFeatureState(FeatureFlags.ConnectAudioMessages);
    const isFrozenChannel = !!channel?.data?.frozen;

    if (isFrozenChannel && !userCanUseFrozenChannel) {
        return (
            <MessageInputPlaceholder
                icon={<InfoIcon />}
                text={`${t('This channel is frozen and only intended for moderated announcements')}`}
            />
        );
    }

    return (
        <MessageInput 
            focus
            maxRows={5}
            grow
            mentionQueryParams={{filters: {deactivated_at:{ $exists: false}} }}
            audioRecordingConfig={{ transcoderConfig: { encoder: encodeToMp3 } }}
            audioRecordingEnabled={isConnectAudioMessagesEnabled}
            asyncMessagesMultiSendEnabled
        />
    );
}
