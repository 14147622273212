import {
  PollCreationDialog,
} from "stream-chat-react";
import type {
  AttachmentSelectorAction,
  AttachmentSelectorActionProps,
  AttachmentSelectorModalContentProps,
} from "stream-chat-react";
import { AttachmentSelector } from "./GetStream/AttachmentSelector";
import { MessageInputControlButton } from "./MessageInputControls";
import { FeatureFlags } from "utils/featureFlags";
import { useFeatureState } from "utils/hooks/useFeatureState";

// Define the menu button
const AddPollAttachmentAction = ({
  closeMenu,
  openModalForAction,
}: AttachmentSelectorActionProps) => (
    <MessageInputControlButton
        type="pollIcon"
        onClick={() => {
          openModalForAction("createPoll");
          closeMenu();
        }}
    />
);

// Define the modal contents to be rendered if AddPollAttachmentAction button is clicked
const AddPollModalContent = ({
  close,
}: AttachmentSelectorModalContentProps) => {
  return <PollCreationDialog close={close}/>;
};

// the custom action will be at the top of the menu
const attachmentSelectorActionSet: AttachmentSelectorAction[] = [
  {
    ActionButton: AddPollAttachmentAction,
    ModalContent: AddPollModalContent,
    type: "createPoll",
  },
];

export const CustomPollSelector = () => {
  const isConnectPollsEnabled = useFeatureState(
      FeatureFlags.ConnectPolls
  );

  if(!isConnectPollsEnabled) return <></>;

  return (
    <AttachmentSelector
      attachmentSelectorActionSet={attachmentSelectorActionSet}
    />
  );
}
