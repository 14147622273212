import React, { useCallback, useEffect, useState } from "react";
import {
    AudioRecorder as AudioRecorderStream,
    DefaultStreamChatGenerics,
    MediaRecordingState,
    RecordingPermissionDeniedNotification,
    StartRecordingAudioButton,
    useMessageInputContext,
} from "stream-chat-react";
import { FeatureFlags } from "utils/featureFlags";
import { useFeatureState } from "utils/hooks/useFeatureState";
import {
    RecordingAttachmentType,
    RecordingPermission,
} from "./GetStream/encoders/mp3";
import { useCustomTextEditorStyles } from "../../utils/customTextEditor";
import { useActions } from "utils/store/useActions";
import { userRecording } from "api/getStream/getStreamActions";

export const RecordingAudio = ({ isEdition } :{ isEdition: boolean; }) => {
    const isConnectAudioMessagesEnabled = useFeatureState(
        FeatureFlags.ConnectAudioMessages
    );
    const { asyncMessagesMultiSendEnabled, attachments, recordingController } =
        useMessageInputContext<DefaultStreamChatGenerics>("MessageInputFlat");
    const [
        showRecordingPermissionDeniedNotification,
        setShowRecordingPermissionDeniedNotification,
    ] = useState(false);
    const closePermissionDeniedNotification = useCallback(() => {
        setShowRecordingPermissionDeniedNotification(false);
    }, []);
    const clasess = useCustomTextEditorStyles();
    const actions = useActions({ userRecording });

    if (!isConnectAudioMessagesEnabled || isEdition) return <></>;

    const isRecording = !!recordingController?.recordingState;

    return (
        <div className={clasess.recordingAudio}>
            {recordingController.permissionState === "denied" &&
                showRecordingPermissionDeniedNotification && (
                    <RecordingPermissionDeniedNotification
                        onClose={closePermissionDeniedNotification}
                        permissionName={RecordingPermission.MIC}
                    />
                )}
            <StartRecordingAudioButton
                disabled={
                    isRecording ||
                    (!asyncMessagesMultiSendEnabled &&
                        attachments.some(
                            (a) =>
                                a.type ===
                                RecordingAttachmentType.VOICE_RECORDING
                        ))
                }
                onClick={() => {
                    recordingController.recorder?.start();
                    setShowRecordingPermissionDeniedNotification(true);
                    actions.current.userRecording(MediaRecordingState.RECORDING)
                }}
            />
        </div>
    );
};

export const AudioRecorder = () => {
    const clasess = useCustomTextEditorStyles();
    const actions = useActions({ userRecording });
    const cleanRecording = useCallback(() => actions.current?.userRecording(null),[actions]);

    useEffect(()=>{
        return() => {
            cleanRecording();
        }
    },[cleanRecording]);

    return (
        <div className={clasess.audioRecorder}>
            <AudioRecorderStream />
        </div>
    );
};
