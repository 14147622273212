/* eslint-disable @typescript-eslint/naming-convention */
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import { StreamChatChannelType } from 'modules/connect/messenger/model';
import { countChannelUnreads } from 'modules/connect/messenger/utils/channels';
import { Channel, ChannelOptions, ChannelSort, StreamChat } from 'stream-chat';
import { SerializableError } from 'utils/error/serializableError';
import { createObservable, Observable } from 'utils/helpers/observable';
import { logger } from 'utils/logger';
import { ClientService } from './ClientService';

type ConversationType = StreamChatChannelType.Team | StreamChatChannelType.Messaging;

/** Maximum channels limit per request - constraint from stream chat API */
const MAX_CHANNELS_LIMIT_PER_REQUEST = 30;

const sort: ChannelSort = {
    has_unread: -1,
    last_message_at: -1,
};

const options: ChannelOptions = {
    state: true,
    watch: true,
    limit: MAX_CHANNELS_LIMIT_PER_REQUEST,
};

export class ConversationsService {
    private _clientService: ClientService | null = null;

    public chats: Observable<Array<Channel>>;
    public channels: Observable<Array<Channel>>;

    public totalUnreadCount: Observable<number>;

    public channelsInitialized: Observable<boolean>;
    public chatsInitialized: Observable<boolean>;

    constructor(clientService: ClientService) {
        this._clientService = clientService;
        this.channels = createObservable([]);
        this.channels.onChange(items => this.traverseConversations(items));
        this.chats = createObservable([]);
        this.chats.onChange(items => this.traverseConversations(items));
        this.totalUnreadCount = createObservable(0);
        this.channelsInitialized = createObservable(false);
        this.chatsInitialized = createObservable(false);
    }

    public setActiveConversation(conversation: Channel | null) {
        if (conversation) {
            if (conversation.countUnread() || conversation.countUnreadMentions()) {
                conversation.markRead();
            }
        }
    }

    private traverseConversations(conversations: Array<Channel>) {
        conversations.forEach(conversation => {
            const isInvited = get(conversation, 'state.membership.invited', false);
            const inviteAcceptDate = get(conversation, 'state.membership.invite_accepted_at', null);
            if (isInvited && !inviteAcceptDate) {
                conversation.acceptInvite();
            }
            if (countChannelUnreads(conversation) === 0 && conversation.countUnread() !== 0) {
                conversation.markRead();
            }
        });
        this.refreshTotalUnreadCount();
    }

    public removeConversation(cid: string) {
        const excludeCid = (item: { cid: string; }) => item.cid !== cid;
        const filteredChannels = this.channels.value.filter(excludeCid);
        if (filteredChannels.length !== this.channels.value.length) {
            this.channels.value = filteredChannels;
        } else {
            this.chats.value = this.chats.value.filter(excludeCid);
        }
    }

    public hideChat(cid: string) {
        this.chats.value = this.chats.value.filter(item => item.cid !== cid);
    }

    public async refreshOrGetConversation(cid: string) {
        try {
            let result = await this._clientService?.client?.queryChannels({ cid });
            if (!result || !result.length) {
                result = await this._clientService?.client?.queryChannels({ cid, hidden: true });
                if (result && result.length) {
                    result[0].show();
                }
            }
            if (result && result.length) {
                const conversation = result[0];
                if (conversation.type === StreamChatChannelType.Team) {
                    this.channels.value = this.replaceOrAdd(conversation, this.channels.value);
                } else if (conversation.type === StreamChatChannelType.Messaging) {
                    this.chats.value = this.replaceOrAdd(conversation, this.chats.value);
                }
                return conversation;
            }
            return null;
        } catch (error) {
            logger.reportError(
                new Error(`Failed to get conversation by CID: ${cid}`),
                JSON.stringify({ ...new SerializableError(error), cid }, null, 4),
            );
            return null;
        }
    }

    private replaceOrAdd(conversation: Channel, targetList: Array<Channel>): Array<Channel> {
        const index = targetList.findIndex(item => item.cid === conversation.cid);
        if (index < 0) {
            return uniqBy([conversation, ...targetList], 'cid');
        } else {
            const innerList = [...targetList];
            innerList.splice(index, 1, conversation);
            return uniqBy(innerList, 'cid');
        }
    }

    public async refreshChannels() {
        this.channels.value = await this.getConversations(StreamChatChannelType.Team);
        this.channelsInitialized.value = true;
    }

    public async refreshChats() {
        this.chats.value = await this.getConversations(StreamChatChannelType.Messaging);
        this.chatsInitialized.value = true;
    }

    public refreshTotalUnreadCount(): void {
        this.totalUnreadCount.value = [
            ...this.channels.value,
            ...this.chats.value,
        ]
            .map(item => countChannelUnreads(item))
            .reduce((sum, itemCount) => sum + itemCount, 0);
    }

    private async getConversations(type: ConversationType): Promise<Array<Channel>> {
        const conversations: Array<Channel> = [];
        const asyncGenerator = this.getConversationsGenerator(type);
        let iterator = await asyncGenerator.next();
        while (!iterator.done && iterator.value.length) {
            conversations.splice(conversations.length, 0, ...iterator.value);
            iterator = await asyncGenerator.next();
        }
        return uniqBy(conversations, 'cid');
    }

    private async *getConversationsGenerator(type: ConversationType) {
        let page = 0;
        while (page >= 0) {
            const conversations = await this.getConversationsPerPage(type, page);
            yield conversations;
            if (conversations.length === MAX_CHANNELS_LIMIT_PER_REQUEST) {
                page++;
            } else {
                page = -1;
            }
        }
    }

    private async getConversationsPerPage(type: ConversationType, page = 0): Promise<Array<Channel>> {
        if (!this._clientService?.isConnected) return [];
        const client = this._clientService.client as StreamChat;
        const offset = page * MAX_CHANNELS_LIMIT_PER_REQUEST;
        const totalOptions = offset > 0 ? { ...options, offset } : options;
        try {
            const conversations = await client.queryChannels({ type }, sort, totalOptions);
            return conversations;
        } catch (error) {
            logger.reportError(
                new Error(`Failed to get conversations of type "${type}"`),
                JSON.stringify(new SerializableError(error), null, 4),
            );
            return [];
        }
    }

    public dispose() {
        this.channels.dispose();
        this.chats.dispose();
        this._clientService = null;
    }

}
