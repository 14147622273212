import React, { useCallback, useMemo } from "react";
import type { UnknownType } from 'stream-chat';
import { DefaultStreamChatGenerics, EmojiSearchIndex, LoadingIndicator, useChannelStateContext, useChatContext, useComponentContext, useMessageInputContext, useTranslationContext } from "stream-chat-react";
import { CustomTrigger } from "stream-chat-react/dist/types/types";
import CustomGetStreamAutocomplete from "./CustomGetStreamAutocomplete";
import { StreamChatType } from "./hooks/types";

export type ChatAutoCompleteProps<T extends UnknownType = UnknownType> = {
    /** Function to override the default submit handler on the underlying `textarea` component */
    handleSubmit?: (event: React.BaseSyntheticEvent) => void;
    /** Function to run on blur of the underlying `textarea` component */
    onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
    /** Function to override the default onChange behavior on the underlying `textarea` component */
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
    /** Function to run on focus of the underlying `textarea` component */
    onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
    /** Function to override the default onPaste behavior on the underlying `textarea` component */
    onPaste?: (event: React.ClipboardEvent<HTMLTextAreaElement>) => void;
    /** Placeholder for the underlying `textarea` component */
    placeholder?: string;
    /** The initial number of rows for the underlying `textarea` component */
    rows?: number;
    /** The text value of the underlying `textarea` component */
    value?: string;
    /** Function to override the default emojiReplace behavior on the `wordReplace` prop of the `textarea` component */
    wordReplace?: (word: string, emojiIndex?: EmojiSearchIndex<T>) => string;
  };
  
  const UnMemoizedChatAutoComplete = <
    StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics,
    V extends CustomTrigger = CustomTrigger
  >(
    props: ChatAutoCompleteProps,
  ) => {
    const {
      AutocompleteSuggestionItem: SuggestionItem,
      AutocompleteSuggestionList: SuggestionList,
    } = useComponentContext<StreamChatGenerics, V>('ChatAutoComplete');
    const { t } = useTranslationContext('ChatAutoComplete');
    const { channel } = useChannelStateContext<StreamChatType>();
    const { client } = useChatContext<StreamChatType>();
  
    const messageInput = useMessageInputContext<StreamChatGenerics, V>('ChatAutoComplete');
    const { cooldownRemaining, disabled, emojiSearchIndex, textareaRef: innerRef, numberOfUploads, mentioned_users, message } = messageInput;
  
    const placeholder = useMemo(() => {
        let dynamicPart = "the group";

        if (channel.type === "team") {
            dynamicPart = `#${
                channel?.data?.name || channel?.data?.id || "random"
            }`;
        }
        const members = Object.values(channel.state.members).filter(
          ({ user }) => user?.id !== client.userID
        );
        if (!members.length || members.length === 1) {
            dynamicPart =
                members[0]?.user?.name ||
                members[0]?.user?.id ||
                "";
        }

        return `${t('Message')} ${dynamicPart}`;
    }, [
        channel.type,
        channel.state.members,
        channel?.data?.id,
        channel?.data?.name,
        client.userID,
        t
    ]);

    const emojiReplace = props.wordReplace
      ? (word: string) => props.wordReplace?.(word, emojiSearchIndex)
      : async (word: string) => {
          const found = (await emojiSearchIndex?.search(word)) || [];
  
          const emoji = found
            .filter(Boolean)
            .slice(0, 10)
            .find(({ emoticons }) => !!emoticons?.includes(word));
  
          if (!emoji) return null;
  
          const [firstSkin] = emoji.skins ?? [];
  
          return emoji.native ?? firstSkin.native;
        };
  
    const updateInnerRef = useCallback(
      (ref: HTMLTextAreaElement | null) => {
        if (innerRef) {
          innerRef.current = ref;
        }
      },
      [innerRef],
    );
  
    return (
      <CustomGetStreamAutocomplete
        additionalTextareaProps={messageInput.additionalTextareaProps}
        aria-label={cooldownRemaining ? t('Slow Mode ON') : placeholder}
        className='str-chat__textarea__textarea str-chat__message-textarea'
        closeCommandsList={messageInput.closeCommandsList}
        closeMentionsList={messageInput.closeMentionsList}
        containerClassName='str-chat__textarea str-chat__message-textarea-react-host'
        disabled={disabled || !!cooldownRemaining}
        disableMentions={messageInput.disableMentions}
        grow={messageInput.grow}
        handleSubmit={props.handleSubmit || messageInput.handleSubmit}
        innerRef={updateInnerRef}
        loadingComponent={LoadingIndicator}
        maxRows={messageInput.maxRows}
        minChar={0}
        minRows={messageInput.minRows}
        onBlur={props.onBlur}
        onChange={props.onChange || messageInput.handleChange}
        onFocus={props.onFocus}
        onPaste={props.onPaste || messageInput.onPaste}
        placeholder={cooldownRemaining ? t('Slow Mode ON') : placeholder}
        replaceWord={emojiReplace}
        rows={props.rows || 1}
        shouldSubmit={messageInput.shouldSubmit}
        showCommandsList={messageInput.showCommandsList}
        showMentionsList={messageInput.showMentionsList}
        SuggestionItem={SuggestionItem}
        SuggestionList={SuggestionList}
        trigger={messageInput.autocompleteTriggers || {}}
        value={props.value || messageInput.text}
        setText={messageInput.setText}
        numberofuploads={numberOfUploads}
        mentionedUsers={mentioned_users}
        isEdition={!!message?.text}
      />
    );
  };
  
  export const ChatAutoComplete = React.memo(
    UnMemoizedChatAutoComplete,
  ) as typeof UnMemoizedChatAutoComplete;
  