import { useSelector } from 'react-redux';
import { nonEmpty } from 'utils/helpers/collections';
import { ItemsById } from 'model/entity';
import { selectLocationsUserTreeById } from 'store/modules/hierarchy/hierarchySelectors';
import { Location as LocationUserTree } from 'api/location/locationModel';
import { useMemo } from 'react';
import { selectUser } from 'store/modules/auth/authSelectors';

export function useUserLocationTree() {
    const locationTreeByUser: ItemsById<LocationUserTree> = useSelector(selectLocationsUserTreeById);
    const currentUser = useSelector(selectUser);
    
    return useMemo(() => {
        const keysLocationsTree = Object.keys(locationTreeByUser);
        if(!keysLocationsTree.length ) return [{
            id: currentUser?.companyLocationId ?? '',
            locationId: currentUser?.companyLocationId ?? '',
            name: currentUser?.companyLocationName ?? '',
            isStore: true,
            storesCount: 0,
            timeZoneId: '',
            icon: undefined
        }];

        return Object.keys(locationTreeByUser)
            .map((loc: string) => {
                if( !locationTreeByUser[loc] ) return undefined;
                return {
                    id: locationTreeByUser[loc]?.locationId ?? '',
                    locationId: locationTreeByUser[loc]?.locationId ?? '',
                    name: locationTreeByUser[loc]?.name ?? '',
                    isStore: locationTreeByUser[loc]?.isStore ?? false,
                    storesCount: locationTreeByUser[loc]?.storesCount ?? 0,
                    timeZoneId: locationTreeByUser[loc]?.timeZoneId ?? '',
                    icon: undefined
                }
            })
            .filter(nonEmpty);
    },[locationTreeByUser, currentUser])
}
