import React, { useCallback, useState } from 'react';
import { AttachmentSelectorAction,
  AttachmentSelectorProps, 
  defaultAttachmentSelectorActionSet,
  DefaultStreamChatGenerics,
  Modal,
  useChannelStateContext,
  useComponentContext,
  useDialog, 
  useMessageInputContext,
  PollCreationDialog as DefaultPollCreationDialog } from 'stream-chat-react';
import { Portal } from './Portal/Portal';
import { CHANNEL_CONTAINER_ID } from './constants';

// This file created on the basis of the getStream: 
// https://github.com/GetStream/stream-chat-react/blob/master/src/components/MessageInput/AttachmentSelector.tsx

const useAttachmentSelectorActionsFiltered = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  original: AttachmentSelectorAction[],
) => {
  const { PollCreationDialog = DefaultPollCreationDialog } = useComponentContext();
  const { channelCapabilities, channelConfig } = useChannelStateContext<StreamChatGenerics>();
  const { isThreadInput } = useMessageInputContext();

  return original
    .filter((action) => {
      if (action.type === 'uploadFile' && !channelCapabilities['upload-file']) return false;
      if (
        action.type === 'createPoll' &&
        (!channelConfig?.polls || isThreadInput || !channelCapabilities['send-poll'])
      )
        return false;
      return true;
    })
    .map((action) => {
      if (action.type === 'createPoll' && !action.ModalContent) {
        return { ...action, ModalContent: PollCreationDialog };
      }
      return action;
    });
};

export const AttachmentSelector = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>({
  attachmentSelectorActionSet = defaultAttachmentSelectorActionSet,
  getModalPortalDestination,
}: AttachmentSelectorProps) => {
  const { isThreadInput } = useMessageInputContext();

  const actions = useAttachmentSelectorActionsFiltered<StreamChatGenerics>(
    attachmentSelectorActionSet,
  );

  const menuDialogId = `attachment-actions-menu${isThreadInput ? '-thread' : ''}`;
  const menuDialog = useDialog({ id: menuDialogId });

  const [modalContentAction, setModalContentActionAction] = useState<AttachmentSelectorAction | undefined>(undefined);
  const openModal = useCallback(
    (actionType: AttachmentSelectorAction['type']) => {
      const action = actions.find((a) => a.type === 'createPoll');
      if (!action?.ModalContent) return;
      setModalContentActionAction(action);
    },
    [actions],
  );

  const closeModal = useCallback(() => setModalContentActionAction(undefined), []);


  const getDefaultPortalDestination = useCallback(
    () => document.getElementById(CHANNEL_CONTAINER_ID),
    [],
  );

  if (actions.length === 0) return null;

  const ModalContent = modalContentAction?.ModalContent;
  const modalIsOpen = !!ModalContent;
  return (
      <div className='str-chat__attachment-selector'>
        {actions.map(({ ActionButton, type }) => (
          <ActionButton
            closeMenu={menuDialog.close}
            key={`attachment-selector-item-${type}`}
            openModalForAction={openModal}
          />
        ))}
        <Portal
          getPortalDestination={getModalPortalDestination ?? getDefaultPortalDestination}
          isOpen={modalIsOpen}
        >
          <Modal className='str-chat__create-poll-modal' onClose={closeModal} open={modalIsOpen}>
            {ModalContent && <ModalContent close={closeModal} />}
          </Modal>
        </Portal>
      </div>
  );
};