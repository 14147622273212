import { IStoreState } from 'store/rootReducer';

export const selectIsChannelCreating = (state: IStoreState) => state.getStream.isChannelCreating;
export const selectIsChannelUpdating = (state: IStoreState) => state.getStream.isChannelUpdating;
export const selectIsChannelDeleting = (state: IStoreState) => state.getStream.isChannelDeleting;
export const selectIsDirectMessagingChatCreating = (
    (state: IStoreState) => state.getStream.isDirectMessageChatCreating
);
export const selectInvitationInProgress = (state: IStoreState) => state.getStream.isInvitationInProgress;
export const selectIsUserRemovingFromChannel = (state: IStoreState) => state.getStream.isUserRemovingFromChannel;

export const selectChatRoleIds = (state: IStoreState) => state.getStream.chatRoleIds;

export const selectIsMessageSearching = (state: IStoreState) => state.getStream.isMessageSearching;
export const selectMessagesById = (state: IStoreState) => state.getStream.foundMessagesById;

export const selectIsChannelImagenUpdating = (state: IStoreState) => state.getStream.isChannelImagenUpdating;
export const selectAddChannelImageId = (state: IStoreState) => state.getStream.addChannelImageId;
export const selectUserRecording = (state: IStoreState) => state.getStream.userRecording;
