import { client as appConfigClient, listFeatureFlags } from 'modules/root/app-configuration';
import { call, delay, put, select, takeEvery } from 'typed-redux-saga';
import { requestFeatureFlags } from './actions';
import { FeatureFlagState } from './model';
import { FEATURE_REQUEST_INTERVAL_MS } from './featureCommonConstants';
import { selectFeatureFlags } from './selectors';
import { ItemsById } from 'model/entity';

function* getFeatureFlags() {
    yield put(requestFeatureFlags.init());
}

function* requestFeatureFlagsInitWatcherSaga() {
    yield takeEvery(
        requestFeatureFlags.initType,
        function* () {
            try {
                const featureFlags: FeatureFlagState[] = yield call(listFeatureFlags, appConfigClient);
                const featureFlagsStorecurrentRoleIds: ItemsById<FeatureFlagState> = yield* select(selectFeatureFlags);
                let hasDifference = false;
                if(Object.keys(featureFlagsStorecurrentRoleIds).length !== featureFlags.length) hasDifference = true;
                
                if(!hasDifference){
                    for (const feature of featureFlags) {
                        if(!featureFlagsStorecurrentRoleIds[feature.id] || feature.enabled !== Boolean(featureFlagsStorecurrentRoleIds[feature.id]?.enabled)){
                            hasDifference = true;
                            break;
                        }
                    }
                }

                if(hasDifference) yield put(requestFeatureFlags.success(featureFlags));

                yield* delay(FEATURE_REQUEST_INTERVAL_MS);
                yield put(requestFeatureFlags.init());
            } catch (error) {
                yield put(requestFeatureFlags.error({ error }));
            }
        },
    );
}

export const featureFlagsSagas = [
    getFeatureFlags,
    requestFeatureFlagsInitWatcherSaga,
];
