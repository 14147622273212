import { IEntityError } from 'model/entity';
import { IResponseSuccessPayload } from 'model/success';
import { createApiActions, createApiActionsByName } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes, createSingleAction } from 'utils/store/actionUtils';
import {
    CreateChannelRequest,
    EditChannelImageRequestType,
    EditChannelRequest,
    IInviteByRolesData,
    InviteToChannelRequest,
    IRemoveChannelMembersRequest, IRemoveChannelRoleSuccessResponse, IRemoveChatRoleData, IRoleInviteSuccessResponse, ISearchChatMsg, ISearchResultData,
    UserRecording,
} from './getStreamModel';
import { PendingFile } from 'store/modules/files/filesModel';

//#region Getting Stream User Token
const REQUEST_STREAM_USER_TOKEN = 'request/getStream/USER_TOKEN';
const RESPONSE_STREAM_USER_TOKEN_SUCCESS = 'response/getStream/USER_TOKEN_SUCCESS';
const RESPONSE_STREAM_USER_TOKEN_ERROR = 'response/getStream/USER_TOKEN_ERROR';

export const requestStreamUserToken = (
    createApiActions<void, string, any,
        typeof REQUEST_STREAM_USER_TOKEN,
        typeof RESPONSE_STREAM_USER_TOKEN_SUCCESS,
        typeof RESPONSE_STREAM_USER_TOKEN_ERROR
    >(
        REQUEST_STREAM_USER_TOKEN,
        RESPONSE_STREAM_USER_TOKEN_SUCCESS,
        RESPONSE_STREAM_USER_TOKEN_ERROR,
    )
);
//#endregion

//#region Creating Stream Channel
const REQUEST_STREAM_CREATE_CHANNEL = 'request/getStream/CREATE_CHANNEL';
const RESPONSE_STREAM_CREATE_CHANNEL_SUCCESS = 'response/getStream/CREATE_CHANNEL_SUCCESS';
const RESPONSE_STREAM_CREATE_CHANNEL_ERROR = 'response/getStream/CREATE_CHANNEL_ERROR';

export const requestCreateChannel = (
    createApiActions<CreateChannelRequest, string, IEntityError,
        typeof REQUEST_STREAM_CREATE_CHANNEL,
        typeof RESPONSE_STREAM_CREATE_CHANNEL_SUCCESS,
        typeof RESPONSE_STREAM_CREATE_CHANNEL_ERROR
    >(
        REQUEST_STREAM_CREATE_CHANNEL,
        RESPONSE_STREAM_CREATE_CHANNEL_SUCCESS,
        RESPONSE_STREAM_CREATE_CHANNEL_ERROR,
    )
);
//#endregion

//#region Creating Stream Direct Messaging Chat
const REQUEST_STREAM_CREATE_DIRECT_MESSAGE_CHAT = 'request/getStream/CREATE_DIRECT_MESSAGE_CHAT';
const RESPONSE_STREAM_CREATE_DIRECT_MESSAGE_CHAT_SUCCESS = 'response/getStream/CREATE_DIRECT_MESSAGE_CHAT_SUCCESS';
const RESPONSE_STREAM_CREATE_DIRECT_MESSAGE_CHAT_ERROR = 'response/getStream/CREATE_DIRECT_MESSAGE_CHAT_ERROR';

export const requestCreateDirectMessageChat = (
    createApiActions<string, string, IEntityError,
        typeof REQUEST_STREAM_CREATE_DIRECT_MESSAGE_CHAT,
        typeof RESPONSE_STREAM_CREATE_DIRECT_MESSAGE_CHAT_SUCCESS,
        typeof RESPONSE_STREAM_CREATE_DIRECT_MESSAGE_CHAT_ERROR
    >(
        REQUEST_STREAM_CREATE_DIRECT_MESSAGE_CHAT,
        RESPONSE_STREAM_CREATE_DIRECT_MESSAGE_CHAT_SUCCESS,
        RESPONSE_STREAM_CREATE_DIRECT_MESSAGE_CHAT_ERROR,
    )
);
//#endregion

//#region Invite to channel
const REQUEST_STREAM_INVITE_TO_CHANNEL = 'request/getStream/INVITE_TO_CHANNEL';
const RESPONSE_STREAM_INVITE_TO_CHANNEL_SUCCESS = 'response/getStream/INVITE_TO_CHANNEL_SUCCESS';
const RESPONSE_STREAM_INVITE_TO_CHANNEL_ERROR = 'response/getStream/INVITE_TO_CHANNEL_ERROR';

export const requestInviteToChannel = (
    createApiActions<InviteToChannelRequest, IResponseSuccessPayload, IEntityError,
        typeof REQUEST_STREAM_INVITE_TO_CHANNEL,
        typeof RESPONSE_STREAM_INVITE_TO_CHANNEL_SUCCESS,
        typeof RESPONSE_STREAM_INVITE_TO_CHANNEL_ERROR
    >(
        REQUEST_STREAM_INVITE_TO_CHANNEL,
        RESPONSE_STREAM_INVITE_TO_CHANNEL_SUCCESS,
        RESPONSE_STREAM_INVITE_TO_CHANNEL_ERROR,
    )
);
//#endregion

//#region Make invite to a channel by user roles
const REQUEST_STREAM_INVITE_BY_ROLES = 'request/getStream/INVITE_BY_ROLES';
const RESPONSE_STREAM_INVITE_BY_ROLES_SUCCESS = 'response/getStream/INVITE_BY_ROLES_SUCCESS';
const RESPONSE_STREAM_INVITE_BY_ROLES_ERROR = 'response/getStream/INVITE_BY_ROLES_ERROR';

export const requestInviteByRoles = (
    createApiActions<IInviteByRolesData, IRoleInviteSuccessResponse, IEntityError,
        typeof REQUEST_STREAM_INVITE_BY_ROLES,
        typeof RESPONSE_STREAM_INVITE_BY_ROLES_SUCCESS,
        typeof RESPONSE_STREAM_INVITE_BY_ROLES_ERROR
    >(
        REQUEST_STREAM_INVITE_BY_ROLES,
        RESPONSE_STREAM_INVITE_BY_ROLES_SUCCESS,
        RESPONSE_STREAM_INVITE_BY_ROLES_ERROR,
    )
);
//#endregion

//#region Edit channel
const REQUEST_STREAM_EDIT_CHANNEL = 'request/getStream/EDIT_CHANNEL';
const RESPONSE_STREAM_EDIT_CHANNEL_SUCCESS = 'response/getStream/EDIT_CHANNEL_SUCCESS';
const RESPONSE_STREAM_EDIT_CHANNEL_ERROR = 'response/getStream/EDIT_CHANNEL_ERROR';

export const requestEditChannel = (
    createApiActions<EditChannelRequest, void, IEntityError,
        typeof REQUEST_STREAM_EDIT_CHANNEL,
        typeof RESPONSE_STREAM_EDIT_CHANNEL_SUCCESS,
        typeof RESPONSE_STREAM_EDIT_CHANNEL_ERROR
    >(
        REQUEST_STREAM_EDIT_CHANNEL,
        RESPONSE_STREAM_EDIT_CHANNEL_SUCCESS,
        RESPONSE_STREAM_EDIT_CHANNEL_ERROR,
    )
);

const REQUEST_STREAM_EDIT_CHANNEL_IMAGE = 'request/getStream/EDIT_CHANNEL_IMAGE';
const RESPONSE_STREAM_EDIT_CHANNEL_IMAGE_SUCCESS = 'response/getStream/EDIT_CHANNEL_IMAGE_SUCCESS';
const RESPONSE_STREAM_EDIT_CHANNEL_IMAGE_ERROR = 'response/getStream/EDIT_CHANNEL_IMAGE_ERROR';

export const requestEditChannelImage = (
    createApiActions<EditChannelImageRequestType, EditChannelRequest, IEntityError,
        typeof REQUEST_STREAM_EDIT_CHANNEL_IMAGE,
        typeof RESPONSE_STREAM_EDIT_CHANNEL_IMAGE_SUCCESS,
        typeof RESPONSE_STREAM_EDIT_CHANNEL_IMAGE_ERROR
    >(
        REQUEST_STREAM_EDIT_CHANNEL_IMAGE,
        RESPONSE_STREAM_EDIT_CHANNEL_IMAGE_SUCCESS,
        RESPONSE_STREAM_EDIT_CHANNEL_IMAGE_ERROR,
    )
);

const REQUEST_STREAM_ADD_CHANNEL_IMAGE = `getStream/REQUEST_STREAM_ADD_CHANNEL_IMAGE`;

export const requestAddChannelImage = createApiActionsByName<PendingFile, string, any, typeof REQUEST_STREAM_ADD_CHANNEL_IMAGE>(
    REQUEST_STREAM_ADD_CHANNEL_IMAGE
);

export const RESET_STREAM_ADD_CHANNEL_IMAGE = `getStream/RESET_STREAM_ADD_CHANNEL_IMAGE`;

export const resetAddChannelImage = createSingleAction<void, typeof RESET_STREAM_ADD_CHANNEL_IMAGE>(
    RESET_STREAM_ADD_CHANNEL_IMAGE,
);

export const USER_RECORDING = `getStream/USER_RECORDING`;

export const userRecording = createSingleAction<UserRecording, typeof USER_RECORDING>(
    USER_RECORDING,
);
//#endregion

//#region Delete channel
const REQUEST_STREAM_DELETE_CHANNEL = 'request/getStream/DELETE_CHANNEL';
const RESPONSE_STREAM_DELETE_CHANNEL_SUCCESS = 'response/getStream/DELETE_CHANNEL_SUCCESS';
const RESPONSE_STREAM_DELETE_CHANNEL_ERROR = 'response/getStream/DELETE_CHANNEL_ERROR';

export const requestDeleteChannel = (
    createApiActions<string, IResponseSuccessPayload, IEntityError,
        typeof REQUEST_STREAM_DELETE_CHANNEL,
        typeof RESPONSE_STREAM_DELETE_CHANNEL_SUCCESS,
        typeof RESPONSE_STREAM_DELETE_CHANNEL_ERROR
    >(
        REQUEST_STREAM_DELETE_CHANNEL,
        RESPONSE_STREAM_DELETE_CHANNEL_SUCCESS,
        RESPONSE_STREAM_DELETE_CHANNEL_ERROR,
    )
);
//#endregion

//#region Remove members from a channel
const REQUEST_STREAM_REMOVE_CHANNEL_MEMBERS = 'request/getStream/REMOVE_CHANNEL_MEMBERS';
const RESPONSE_STREAM_REMOVE_CHANNEL_MEMBERS_SUCCESS = 'response/getStream/REMOVE_CHANNEL_MEMBERS_SUCCESS';
const RESPONSE_STREAM_REMOVE_CHANNEL_MEMBERS_ERROR = 'response/getStream/REMOVE_CHANNEL_MEMBERS_ERROR';

export const requestRemoveChannelMembers = (
    createApiActions<IRemoveChannelMembersRequest, void, IEntityError,
        typeof REQUEST_STREAM_REMOVE_CHANNEL_MEMBERS,
        typeof RESPONSE_STREAM_REMOVE_CHANNEL_MEMBERS_SUCCESS,
        typeof RESPONSE_STREAM_REMOVE_CHANNEL_MEMBERS_ERROR
    >(
        REQUEST_STREAM_REMOVE_CHANNEL_MEMBERS,
        RESPONSE_STREAM_REMOVE_CHANNEL_MEMBERS_SUCCESS,
        RESPONSE_STREAM_REMOVE_CHANNEL_MEMBERS_ERROR,
    )
);
//#endregion

//#region Remove Roles from a channel
const REQUEST_STREAM_REMOVE_CHANNEL_ROLES = 'request/getStream/REMOVE_CHANNEL_ROLES';
const RESPONSE_STREAM_REMOVE_CHANNEL_ROLES_SUCCESS = 'response/getStream/REMOVE_CHANNEL_ROLES_SUCCESS';
const RESPONSE_STREAM_REMOVE_CHANNEL_ROLES_ERROR = 'response/getStream/REMOVE_CHANNEL_ROLES_ERROR';

export const requestRemoveChannelRoles = (
    createApiActions<IRemoveChatRoleData, IRemoveChannelRoleSuccessResponse, IEntityError,
        typeof REQUEST_STREAM_REMOVE_CHANNEL_ROLES,
        typeof RESPONSE_STREAM_REMOVE_CHANNEL_ROLES_SUCCESS,
        typeof RESPONSE_STREAM_REMOVE_CHANNEL_ROLES_ERROR
        >(
        REQUEST_STREAM_REMOVE_CHANNEL_ROLES,
        RESPONSE_STREAM_REMOVE_CHANNEL_ROLES_SUCCESS,
        RESPONSE_STREAM_REMOVE_CHANNEL_ROLES_ERROR,
    )
);
//#endregion

//#region Get chat roles section
const REQUEST_CHAT_ROLES = 'chat/ROLES';
export const requestChatRoles = createApiActionsByName<string, IInviteByRolesData, any, typeof REQUEST_CHAT_ROLES>(
    REQUEST_CHAT_ROLES);
//#endregion

//#region Search chats section
const REQUEST_CHAT_MSG_SEARCH = 'chat/MSG_SEARCH';
export const requestChatMsgSearch = createApiActionsByName<ISearchChatMsg, ISearchResultData, any,
    typeof REQUEST_CHAT_MSG_SEARCH>(REQUEST_CHAT_MSG_SEARCH)
//#endregion

export type CreateConversationSuccessActions = (
    ReturnType<typeof requestCreateChannel.success> |
    ReturnType<typeof requestCreateDirectMessageChat.success>
);

export type GetStreamActions = (
    ActionsReturnTypes<typeof requestRemoveChannelMembers> |
    ActionsReturnTypes<typeof requestDeleteChannel> |
    ActionsReturnTypes<typeof requestEditChannel> |
    ActionsReturnTypes<typeof requestEditChannelImage> |
    ActionsReturnTypes<typeof requestInviteByRoles> |
    ActionsReturnTypes<typeof requestInviteToChannel> |
    ActionsReturnTypes<typeof requestStreamUserToken> |
    ActionsReturnTypes<typeof requestCreateChannel> |
    ActionsReturnTypes<typeof requestCreateDirectMessageChat> |
    ActionsReturnTypes<typeof requestChatRoles> |
    ActionsReturnTypes<typeof requestRemoveChannelRoles> |
    ActionsReturnTypes<typeof requestChatMsgSearch> |
    ActionsReturnTypes<typeof requestAddChannelImage> |
    ReturnType<typeof resetAddChannelImage> |
    ReturnType<typeof userRecording>
);

export type RoleFromChatActions = (
    ActionsReturnTypes<typeof requestRemoveChannelRoles> |
    ActionsReturnTypes<typeof requestInviteByRoles>
);

export type GetStreamRoleMembersActions = (
    ActionsReturnTypes<typeof requestChatRoles>
);
