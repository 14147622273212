import React, { useCallback, useEffect } from 'react';
import {
    Channel as ConversationComponent,
    MessageList,
    Thread,
    ThreadHeader,
    useChatContext,
    Window,
} from 'stream-chat-react';
import { ChatHeader } from './ChatHeader';
import { ChannelPlaceholder } from './placeholders/ChannelPlaceholder';
import { StreamChatChannelType } from '../model';
import { TeamMessageInput } from './MessageInput/TeamMessageInput';
import { DirectMessageInput } from './MessageInput/DirectMessageInput';
import { getDirectMessageUserId } from '../hooks/useDirectMessageUser';
import { useDispatch } from 'react-redux';
import { requestUserInfos } from 'store/modules/user/userActions';
import { CustomMessageInput } from './MessageInput/CustomMessageInput';
import { FeatureFlags } from 'utils/featureFlags';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { PinnedMessageList } from './ChatHeader/chatActions/PinAction/PinnedMessageList/PinnedMessageList';
import { SearchIndex } from 'emoji-mart';
import { CustomPinIndicator } from './TeamMessage/CustomPinIndicator';
import { RenderText } from './Message/RenderText';
import { CustomPollActions } from './MessageInput/pollActions/CustomPollActions';

export function ChatContent() {
    const dispatch = useDispatch();
    const { channel, client } = useChatContext();
    const isTeamChannel = channel?.data?.type === StreamChatChannelType.Team;
    const isCustomInputConectEnabled = useFeatureState(FeatureFlags.CustomInputConnect);

    useEffect(() => {
        if (channel?.data?.type === StreamChatChannelType.Messaging) {
            const userId = getDirectMessageUserId(client, channel);

            if (userId) {
                dispatch(requestUserInfos([userId]));
            }
        }
    }, [channel, client, dispatch])

    const propsCustomInput = useCallback(() => (
        isCustomInputConectEnabled ? {
            Input:CustomMessageInput,
            EditMessageInput:CustomMessageInput
        } : {}
    ), [isCustomInputConectEnabled]);

    return (
        <React.Fragment>
            <ConversationComponent
                EmptyPlaceholder={<ChannelPlaceholder />}
                emojiSearchIndex={SearchIndex}
                ThreadHeader={ThreadHeader}
                PinIndicator={CustomPinIndicator}
                PollActions={CustomPollActions}
                {...propsCustomInput()}
            >
                    <Window>
                        <ChatHeader />
                        <MessageList
                            renderText={(text, mentioned_users, options)=>(RenderText(text, mentioned_users, options))}
                        />
                        {isTeamChannel
                            ? <TeamMessageInput />
                            : <DirectMessageInput />}
                    </Window>
                    <Thread virtualized/>
                    <PinnedMessageList />
            </ConversationComponent>
        </React.Fragment>
    );
}
