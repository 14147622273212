import React from "react";
import { MessageInput, useChatContext } from "stream-chat-react";
import { useDirectMessageUser } from "../../hooks/useDirectMessageUser";
import { MessageInputPlaceholder } from "../placeholders/MessageInputPlaceholder";
import { t } from "i18next";
import { useFeatureState } from "utils/hooks/useFeatureState";
import { FeatureFlags } from "utils/featureFlags";
import { encodeToMp3 } from "./GetStream/encoders/mp3";

export function DirectMessageInput() {
    const chatContext = useChatContext();
    const user = useDirectMessageUser(chatContext);
    const isConnectAudioMessagesEnabled = useFeatureState(
        FeatureFlags.ConnectAudioMessages
    );

    if (user?.isDeleted) {
        return (
            <MessageInputPlaceholder
                text={`${t(
                    "This user has been deactivated and cannot be messaged"
                )}`}
            />
        );
    }

    return (
        <MessageInput
            focus
            maxRows={5}
            grow
            mentionQueryParams={{
                filters: { deactivated_at: { $exists: false } },
            }}
            audioRecordingConfig={{
                transcoderConfig: { encoder: encodeToMp3 },
            }}
            audioRecordingEnabled={isConnectAudioMessagesEnabled}
            asyncMessagesMultiSendEnabled
        />
    );
}
