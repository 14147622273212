import { EndPollDialog, PollActions, PollAnswerList, PollOptionsFullList, PollResults } from "stream-chat-react";
import { SuggestPollOptionForm } from "./SuggestPollOptionForm";
import { AddCommentForm } from "./AddCommentForm";

export const CustomPollActions = () => (
    <PollActions
        AddCommentForm={AddCommentForm}
        EndPollDialog={EndPollDialog}
        PollAnswerList={PollAnswerList}
        PollOptionsFullList={PollOptionsFullList}
        PollResults={PollResults}
        SuggestPollOptionForm={SuggestPollOptionForm}
    />
);
