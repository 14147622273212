/* eslint-disable @typescript-eslint/naming-convention */
import get from 'lodash/get';
import { StreamChatEvents } from 'modules/connect/messenger/events';
import { StreamChatChannelType } from 'modules/connect/messenger/model';
import { Event } from 'stream-chat';
import { ClientService } from './ClientService';
import { ConversationsService } from './ConversationsService';
import { store } from 'store/configureStore';
import { selectUserRecording } from 'store/modules/getStream/getStreamSelectors';
import { UserRecording } from 'api/getStream/getStreamModel';

const recalcUnreadEvents = [
    StreamChatEvents.NotificationMarkRead,
    StreamChatEvents.NotificationMessageNew,
    StreamChatEvents.MessageNew,
    StreamChatEvents.MessageDeleted,
    StreamChatEvents.MessageRead,
];

const channelUpdatingEvents = [
    StreamChatEvents.NotificationAddedToChannel,
    StreamChatEvents.NotificationMessageNew,
    StreamChatEvents.MessageNew,
    StreamChatEvents.ChannelUpdated,
    StreamChatEvents.NotificationInvited,
    StreamChatEvents.NotificationInviteAccepted,
];

const deletingChannelEvents = [
    StreamChatEvents.NotificationChannelDeleted,
    StreamChatEvents.ChannelDeleted,
    StreamChatEvents.NotificationRemovedFromChannel,
];

export class EventService {

    private _clientService: ClientService | null = null;
    private _conversationsService: ConversationsService | null = null;
    private _unsubscribeActions: Array<() => void>;
    private _unsubscribe: () => void;
    private recordingState: UserRecording = null;

    constructor(clientService: ClientService, conversationsService: ConversationsService) {
        this._unsubscribe = store.subscribe(this.handleStoreChange);
        this._clientService = clientService;
        this._conversationsService = conversationsService;
        this._unsubscribeActions = [];
    }
    handleStoreChange = () => {
        const state = store.getState();
        this.recordingState = selectUserRecording(state);
    };
    
    cleanupStoreListener() {
        this._unsubscribe();
    }

    public initialize() {
        const description = this._clientService?.client?.on((event: Event) => {
            if( !this.recordingState){
                if (channelUpdatingEvents.includes(event.type as StreamChatEvents)) {
                    this.handleUpdateConversations(event);
                }
                if (recalcUnreadEvents.includes(event.type as StreamChatEvents) || get(event, 'total_unread_count', null) !== null) {
                    this.handleRecalcUnreads();
                }
            }
            if (deletingChannelEvents.includes(event.type as StreamChatEvents)) {
                this.handleDeleteConversation(event);
            }
        });
        this._unsubscribeActions.push(() => description?.unsubscribe());
    }

    private handleUpdateConversations(event: Event) {
        const cid = this.getCid(event);
        const conversationType = event.channel_type;
        if (!cid && !conversationType) {
            this._conversationsService?.refreshChannels();
            this._conversationsService?.refreshChats();
        } else if (cid) {
            this._conversationsService?.refreshOrGetConversation(cid);
        } else if (conversationType) {
            switch (conversationType) {
                case StreamChatChannelType.Team:
                    this._conversationsService?.refreshChannels();
                    break;
                case StreamChatChannelType.Messaging:
                    this._conversationsService?.refreshChats();
                    break;
            }
        }
    }

    private handleRecalcUnreads() {
        this._conversationsService?.refreshTotalUnreadCount();
    }

    private handleDeleteConversation(event: Event) {
        const cid = this.getCid(event);
        if (cid) {
            this._conversationsService?.removeConversation(cid);
        }
    }

    private getCid(event: Event): string | null {
        let cid: unknown = get(event, 'cid', null);
        if (!cid) {
            cid = get(event, 'channel.cid', null);
        }
        return cid as (string | null);
    }

    public dispose() {
        this._unsubscribeActions.forEach(unsubsribe => unsubsribe());
        this._unsubscribeActions.length = 0;
        this._conversationsService = null;
        this._clientService = null;
        this.cleanupStoreListener();
    }

}
